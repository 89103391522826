<template>
	<div class="privacy">
		<!-- 头部 -->
		<logged-heard></logged-heard>
		<!-- 内容区域 -->
		<!-- 手机号获取验证码登录注册-->
		<div class="login" v-if="!hasLogin">
			<div class="message">
				<div class="el-login-register pointer" @click="submitClick">登录/注册</div>
				<el-col :span="2">
					<el-input v-model="query.code" placeholder="验证码">
						<template slot="append">
							<div @click="codeClick">{{ second }}</div>
						</template>
					</el-input>
				</el-col>
				<el-col :span="2">
					<el-input v-model="query.phone" placeholder="输入手机号"></el-input>
				</el-col>
			</div>
		</div>
		<!-- 搜索 -->
		<div class="search">
			<search :jobCity="false" :particular="false"></search>
		</div>
		<div class="A-module">
			<div style="position: relative;" class="left-message">
				<div @mouseleave="floatLeave()" class="left">
					<!-- 列表 -->
					<div class="category-list" v-for="item in jobList.slice(0, 5)" :key="item.id" @mouseenter="mouseenter(item)">
						<!-- 职位名称 -->
						<div class="title-name">
							<span class="text-main font-weight-bolder">{{ item.title }}</span>
						</div>
						<div class="job">
							<div class="item pointer" v-for="itemOne in item.childs.slice(0, 3)" :key="itemOne.id"
								@click="navTo('/position', itemOne.id, itemOne.title)">
								<span>{{ itemOne.title }}</span>
							</div>
						</div>
						<i class="el-icon-arrow-right" style="float: right; color: #cccccc"></i>
                    </div>
				</div>
				<div class="right space-between">
					<div class="right-img-bold direction-between">
						<div class="bold-img pointer" v-if="bannerList.length > 0">
							<el-carousel trigger="click" height="170px">
								<el-carousel-item v-for="(item, index) in bannerList[0].files" :key="index">
									<img :src="item" alt="" class="bold-img" @click="linkTo" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="space-between" v-if="imgString.length > 0">
							<img :src="imgString[0].files[0]" class="bold-img-one pointer" alt=""
								@click="navToOne('/position', 1, imgString[0].position_id)" />
							<img :src="imgString1[0].files[0]" class="bold-img-one pointer" alt=""
								@click="navToOne('/position', 2, imgString1[0].position_id)" />
						</div>
					</div>
					<div class="right-img-noalmor" v-if="imgString2.length > 0">
						<img :src="imgString2[0].files[0]" alt="" class="noalmor-img pointer"
							@click="navToOne('/enterprise', 1, imgString2[0].position_id)" />
					</div>
				</div>
                <div v-if="showFloat" @mouseenter="floatEnter()" @mouseleave="floatLeave()" style="position: absolute;right: 8rem;width: 60rem; display: flex;flex: 1;height: 21.375rem;z-index: 777;border-radius: 0.5rem;color: #61687c;">
                    <div style="width:4rem; height:21.375rem; background: #234234;opacity: 0;"></div>
                    <div style="width:50rem; height:21.375rem; background: #fff; box-shadow: 0 10px 40px 0 rgb(153 153 153 / 20%);border-radius: 0.5rem;">
                        <div style="padding: 10px;" class="title-name">
                            <span style="font-size:17px;" class="text-main font-weight-bolder">{{ floatItems.title }}</span>
                        </div>
                        <span style="font-size:15px;padding: 7px;width: 20%;display: inline-block;cursor:pointer;" v-for="itemOne in floatItems.childs" :key="itemOne.id"
                            @click="navTo('/position', itemOne.id, itemOne.title)">
                            {{ itemOne.title }}
                        </span>
                    </div>
                </div>
			</div>
		</div>

		<div class="title">
			<span>——</span>
			<span>热招职位</span>
			<span>——</span>
		</div>
		<div class="job-list">
			<div class="item-list">
				<!-- 职位列表 -->
				<div class="item pointer" v-for="item in listPosi" :key="item.id"
					@click="navTo('/position-details', item.id)">
					<div class="name-money">
						<span>{{ item.position_name }}</span>
						<span v-if="item.salary_min == '面议'">{{ item.salary_min }}</span>
						<span v-else>{{ item.salary_min }}-{{ item.salary_max }}</span>
					</div>
					<div class="basic-requirements">
						<span>{{ item.province }}｜{{ item.experience }}｜{{ item.education }}</span>
					</div>
					<div class="company-name">
                        <!-- <img :src="item.ent.logo_url" alt="" /> -->
                        <span style="float:left; margin:1.25rem 0.625rem; width:2.1875rem; height:2.1875rem;display: block;border-radius: 10px;border: 1px solid #f2f5fa;text-align: center;line-height: 2.1875rem;background-color: #fff;">
                            <img style="width:100%; height:auto;float:none;margin:0;" :src="item.ent.logo_url" alt="" />
                        </span>
						<div class="right-name">
							<span>{{ item.ent.ent_name }}</span>
							<span v-if="item.ent.other_info">{{ item.ent.other_info.nature.text }}｜{{
								item.ent.other_info.scale.text
							}}｜{{ item.ent.other_info.industry.text }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="more pointer" @click="navToTwo('/position', 1)" v-if="listPosi.length > 8">
			查看更多
		</div>
		<!-- 底部 -->
		<feet-model></feet-model>
	</div>
</template>

<script>
import loggedHeard from "../../components/loggedHeard.vue";
import feetModel from "../../components/feet.vue";
import tabbarModel from "../../components/tabbar.vue";
import search from "../../components/search.vue";
import {
	mapState,
	mapGetters
} from "vuex";
import api from "../../api/user";
import posiApi from "../../api/position";
import ststemApi from "../../api/system";
import toolApi from "../../api/tool";
import apiDuck from "../../api/user"; //hyz
export default {
	components: {
		loggedHeard,
		feetModel,
		tabbarModel,
		search
	},
	computed: {
		// 2. 解构状态作为计算属性
		...mapState(["userDetail"]),
		...mapGetters(["hasLogin"]),
	},
	data() {
		return {
			authcode: "",
			query: {
				code: "",
				phone: "",
			},
			second: "获取",
			showText: true,
			listPosi: [],
			bannerList: [],
			imgString: [],
			imgString1: [],
			imgString2: [],
			jobList: [],
			IMobj: {
				is_flush: 0
			},
            floatItems: [],
            showFloat: false
		};
	},

	created() {
		// apiDuck.userDetail().then((res) => {
		// 	if (res.code == 200) {
		// 		this.$store.commit("SET_USER", res.data);
		// 		if (res.data.user_type == 2) {
		// 			this.$emit("toggleClick");
		// 			return;
		// 		}
		// 	}
		// });

		this.posiList();
		this.advertObj();
		this.jobCategory();
	},

	mounted() { },

	methods: {
        floatEnter(){
            this.showFloat=true
        },
        floatLeave(){
            this.showFloat=false
        },
		linkTo() {
			window.open(this.bannerList[0].link, "_blank");
			ststemApi.advertClick(this.bannerList[0].position_id);
		},
		// 获取职位类别
		jobCategory() {
			const loading = this.$loading({
				lock: true,
				text: '加载中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			toolApi.positionCategory().then((res) => {
				if (res.code == 200) {
					this.jobList = res.data.data;
				}
				loading.close()
			});
		},
		// 跳转
        mouseenter(item) {
            this.floatItems = item
            this.showFloat = true
		},
		navTo(path, id, position) {
			if (id) {
				this.$router.push({
					path,
					query: {
						id,
						position
					}
				});
				return;
			}
			this.$router.push({
				path
			});
		},
		navToTwo(path) {
			this.$router.push({
				path
			});
		},
		navToOne(path, id, position_id) {
			this.$router.push({
				path,
				query: {
					category: id
				}
			});
			if (position_id) {
				ststemApi.advertClick(position_id);
			}
		},
		// 广告图片区域
		advertObj() {
			toolApi.advertObj().then((res) => {
				if (res.code == 200) {
					let data = res.data;
					this.bannerList = data.filter((item) => {
						if (item.position_id == 1) {
							return item;
						}
					});
					this.imgString = data.filter((item) => {
						if (item.position_id == 2) {
							return item;
						}
					});
					this.imgString1 = data.filter((item) => {
						if (item.position_id == 3) {
							return item;
						}
					});
					this.imgString2 = data.filter((item) => {
						if (item.position_id == 4) {
							return item;
						}
					});
				}
			});
		},
		// 热门职位
		posiList() {
			posiApi.posiList({
				is_mine: 0,
				is_home: 1,
				pageSize: 16
			}).then((res) => {
				if (res.code == 200) {
					this.listPosi = res.data.data;
				}
			});
		},
		// 获取用户信息
		getUserInfo() {
			api.userDetail().then((res) => {
				if (res.code == 200) {
					this.$store.commit("SET_USER", res.data);
				}
			});
		},
		// 获取腾讯im
		userSig() {
			api.getUserSig(this.IMobj).then((res) => {
				if (res.code == 200) {
					this.$store.commit("SET_SIG", res.data);
					this.timLogin(res.data.userId, res.data.userSig);
				}
			});
		},
		// 登录腾讯im
		timLogin(userID, userSig) {
			let promise = this.$tim.login({
				userID: userID.toString(),
				userSig
			});
			promise.then((imResponse) => {
				// 登录成功
				if (imResponse.data.repeatLogin === true) {
					// 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
				}
			}).catch((imError) => {
				this.IMobj.is_flush = 1;
				setTimeout(() => {
					this.userSig();
				}, 500);
			});
		},
		// 登录
		submitClick() {
			if (!this.$util.checkStr(this.query.phone, "mobile")) {
				this.$util.msg("请输入正确的手机号码", "error");
				return;
			} else if (this.query.code == "") {
				this.$util.msg("请输入短信验证码", "error");
				return;
			}
			const loading = this.$loading({
				lock: true,
				text: "登录中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			api.smsLogin(this.query).then((res) => {
				this.getUserInfo();
				loading.close();
				if (res.code == 200) {
					this.$util.msg("登录成功");
					let vuexData = {
						access_token: res.data.token,
						userInfo: res.data,
					};
					this.$store.commit("setToken", vuexData);
					if (res.data.user_type == 1) {
						this.userSig();
						this.$router.push({
							name: "Personal-center"
						});
					} else {
						if (res.data.is_enterprise == 0) {
							this.$router.push({
								path: "/recruiter"
							});
						} else {
							api.toggleUser({
								user_type: 2
							}).then((res) => {
								if (res.code == 200) {
									this.$router.push({
										name: "home"
									});
								}
							});
						}
					}
				} else {
					loading.close();
				}
			});
		},
		// 发送短信验证码
		codeClick() {
			if (!this.$util.checkStr(this.query.phone, "mobile")) {
				this.$util.msg("请输入正确的手机号码", "error");
				return;
			}
			const loading = this.$loading({
				lock: true,
				text: "正在获取验证码",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			if (this.showText == false) {
				this.$util.msg("请不要重复点击", "warning");
				loading.close();
				return false;
			} else {
				setTimeout(() => {
					loading.close();
					this.codeCountdown();
					this.sendSms();
				}, 2000);
			}
		},
		// 获取短信验证码
		sendSms() {
			api.getSms({
				phone: this.query.phone
			}).then((res) => {
				if (res.code == 200) {
					this.$util.msg(res.msg, "success");
				}
			});
		},
		// 倒计时
		// 验证码倒计时
		codeCountdown() {
			this.showText = false;
			let time = 60;
			if (time > 0) {
				var a = setInterval(() => {
					time = time - 1;
					this.second = time + "s";
					if (time == 0) {
						clearInterval(a);
						this.showText = true;
						this.second = "获取";
					}
				}, 1000);
			}
		},
	},
};
</script>
<style lang="less" scoped>
.privacy {
	width: 100%;
	background: #f6f6f8;

	.login {
		width: 100%;
		height: 7.5rem;
		background: #126bf9;

		.message {
			padding: 2.8125rem 12rem;

			.el-col-2 {
				width: 127px;
			}

			.el-col {
				float: right;

				margin-right: 0.25rem;

				::v-deep .el-input__inner {
					height: 36px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border: 1px solid #fff;
				}

				::v-deep .el-input-group__append {
					padding: 0 0.5rem;
					background-color: #fff;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					color: #126bf9;
					border: none;
					cursor: pointer;
				}
			}

			.el-login-register {
				float: right;
				width: 82px;
				height: 36px;
				border: 1px solid #ffffff;
				line-height: 36px;
				text-align: center;
				color: #ffffff;
			}
		}
	}

	.search {
		width: 70.125rem;
		margin: 0 auto;
		margin-bottom: 1.875rem;
	}

	.A-module {
		width: 100%;

		// height: 22.875rem;
		.left-message {
			width: 97rem;
			height: 21.375rem;
			margin: 0 auto;
			margin-bottom: 1.875rem;
			display: flex;

			.left {
				flex: 1;
				width: 100%;
				// min-width: 38.5625rem;
				height: 21.375rem;
				overflow: hidden;
				background: #ffffff;

				.category-list {
					width: 100%;
					height: 3.9875rem;
					// padding: 1.975rem 2.0625rem 0 1.9375rem;
					display: flex;
					align-items: center;
					justify-content: space-around;

					.title-name {
						// float: left;
                        width: 6.5rem;
						font-size: 0.9375rem;
						font-weight: bold;
						color: #414560;
					}

					.job {
						// float: left;
						width: 18rem;
						height: 2.8125rem;
						font-size: 1.1rem;
						font-weight: 400;
						color: #61687c;
						display: flex;
						flex-wrap: wrap;
						align-items: center;

						.item {
							margin-left: 1.25rem;
						}
					}
				}
			}
			.right {
				background: #fff;
				margin-left: 1.25rem;
				width: 63.375rem;
				height: 21.375rem;

				.right-img-bold {
					width: 41.875rem;
					height: 100%;

					.bold-img {
						width: 41.875rem;
						height: 10.625rem;
						margin-bottom: 2px;
					}

					.bold-img-one {
						width: 20.875rem;
						height: 10.625rem;
						margin-left: 2px;
					}

					.bold-img-one:first-child {
						margin-left: 2px;
					}
				}

				.right-img-noalmor {
					width: 21rem;
					height: 21.375rem;
					margin-left: 2px;

					.noalmor-img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.title {
		width: 100%;
		height: 3.125rem;
		text-align: center;
		margin-left: -0.5rem;

		span {
			margin-left: 0.5rem;
		}

		span:nth-child(1) {
			color: #d1d4db;
		}

		span:nth-child(2) {
			font-size: 1.5rem;

			font-weight: bold;
			color: #414a60;
		}

		span:nth-child(3) {
			color: #d1d4db;
		}
	}

	.job-list {
		width: 100%;
		overflow-x: hidden;

		.item-list {
			width: 99rem;
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			margin-bottom: 1.25rem;

			//   background: #126bf9;
			.item {
				width: 23.0625rem;
				height: 10.3125rem;
				// margin-left: 1.25rem;
				background: #ffffff;
				margin: 0.825rem;
				flex-shrink: 0;
				margin-bottom: 1.25rem;

				.name-money {
					width: 100%;
					height: 3.4375rem;
					line-height: 3.4375rem;
					padding: 0 1.25rem 0 0.875rem;

					span:nth-child(1) {
						float: left;
						font-size: 0.9375rem;

						font-weight: bold;
						color: #414a60;
					}

					span:nth-child(2) {
						float: right;
						font-size: 0.9375rem;

						font-weight: bold;
						color: #fd7240;
					}
				}

				.basic-requirements {
					width: 93%;
					height: 2.0625rem;
					margin: 0 auto;
					font-size: 0.875rem;

					font-weight: 400;
					color: #8d92a1;
					//   padding: 0 1.25rem 0 0.875rem;
					border-bottom: 1px solid #eee;
				}

				.company-name {
					width: 100%;
					height: 4.75rem;

					img {
						width: 2.1875rem;
						height: 2.1875rem;
						margin: 20px 10px;
						float: left;
					}

					.right-name {
						display: flex;
						flex-direction: column;
						font-size: 0.9375rem;

						font-weight: 400;
						color: #61687c;
						padding: 1.1rem 0 0 0;
					}
				}
			}
		}
	}

	.more {
		width: 13.625rem;
		height: 2.125rem;
		background: #126bf9;
		text-align: center;
		line-height: 2.125rem;
		color: #ffffff;
		margin: 0 auto;
		margin-bottom: 1.25rem;
	}
}
</style>
